<template>
  <div class="marketplace">
    <div>
      <h1 class="font-weight-medium mt-5">
        {{ $t('marketplace.title') }}
      </h1>

      <!-- New Deals -->
      <div
        v-for="(statusList, idx) in assetStatusList"
        :key="idx"
      >
        <div
          v-if="assetsByStatus(statusList).length"
          class="d-flex justify-space-between mt-5 align-center">
          <h3 class="font-weight-medium">
            {{ $t(`marketplace.lifecycleTitle.${statusList[0]}`) }}
          </h3>
          <div class="d-flex align-center">
            <span v-if="assetsByStatus(statusList).length === 1">
              {{ $t('marketplace.numResult.singular', {
                amount: 1,
              }) }}
            </span>
            <span v-else>
              {{ $t('marketplace.numResult.plural', {
                amount: assetsByStatus(statusList).length,
              }) }}
            </span>
          </div>
        </div>
        <div v-if="$vuetify.breakpoint.smAndDown">
          <div v-if="loading" class="d-flex justify-center my-5">
            <v-skeleton-loader
              type="card"
              :width="350"
              :height="405"
              class="rounded-lg mr-5 mb-1"
            ></v-skeleton-loader>
          </div>
          <v-carousel
            v-else-if="assetsByStatus(statusList).length > 1"
            hide-delimiters
            height="auto"
            class="my-5"
          >
            <v-carousel-item
              v-for="product in assetsByStatus(statusList)"
              :key="`carousel${product.id}`"
            >
              <div class="d-flex align-center justify-center">
                <ProductCard
                  :key="`${product.id}`"
                  :id="`product-${product.id}`"
                  @card-click="routeToProductView(product.id)"
                  :product="product"
                  :comingSoon="product.assetStatus === 'Teased'"
                ></ProductCard>
              </div>
            </v-carousel-item>
          </v-carousel>
          <div v-else class="d-flex product-list my-5">
            <ProductCard
              v-if="assetsByStatus(statusList)[0]"
              :key="`${assetsByStatus(statusList)[0].id}`"
              :id="`product-${assetsByStatus(statusList)[0].id}`"
              @card-click="routeToProductView(assetsByStatus(statusList)[0].id)"
              :product="assetsByStatus(statusList)[0]"
              :comingSoon="assetsByStatus(statusList)[0].assetStatus === 'Teased'"
            ></ProductCard>
          </div>
        </div>
        <div v-else>
          <div v-if="loading" class="d-flex my-5">
            <v-skeleton-loader
              v-for="n in 3"
              :key="n"
              type="card"
              :width="350"
              :height="405"
              class="rounded-lg mr-5 mb-1"
            ></v-skeleton-loader>
          </div>
          <div v-else class="d-flex product-list my-5">
            <ProductCard
              v-for="product in assetsByStatus(statusList)"
              :key="`${product.id}`"
              :id="`product-${product.id}`"
              @card-click="routeToProductView(product.id)"
              :product="product"
              :comingSoon="product.assetStatus === 'Teased'"
              class="mr-5 mb-1"
            ></ProductCard>
          </div>
        </div>
      </div>

      <!-- Categories -->
      <div class="d-flex justify-space-between mt-5 align-center">
        <h3 class="font-weight-medium">
          {{ $t('marketplace.categories') }}
        </h3>
        <!-- <div class="d-flex align-center">
          <span>Alle anzeigen</span>
          <v-icon class="ml-1">mdi-chevron-right</v-icon>
        </div> -->
      </div>
      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-carousel
          hide-delimiters
          height="auto"
          class="my-5"
        >
          <v-carousel-item>
            <div class="d-flex align-center justify-center">
              <CategoryCard
                title="Oldtimer"
                icon="mdi-car-hatchback"
                :width="350"
              />
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="d-flex align-center justify-center">
              <CategoryCard
                title="Diamanten"
                icon="mdi-diamond-stone"
                :width="350"
              />
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="d-flex align-center justify-center">
              <CategoryCard
                title="Kunstwerke"
                icon="mdi-brush"
                footer="BALD VERFÜGBAR"
                disabled
                :width="350"
              />
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
      <div v-else class="d-flex my-5">
        <CategoryCard
          title="Oldtimer"
          icon="mdi-car-hatchback"
        />
        <CategoryCard
          title="Diamanten"
          icon="mdi-diamond-stone"
          class="ml-5"
        />
        <CategoryCard
          title="Kunstwerke"
          icon="mdi-brush"
          footer="BALD VERFÜGBAR"
          disabled
          class="ml-5"
        />
      </div>

      <!-- The Best Performance -->
      <!-- <div class="d-flex justify-space-between mt-5">
        <span>Die beste Performance</span>
        <div class="d-flex align-center">
          <span>5 Ergebnisse anzeigen</span>
          <v-icon class="ml-1">mdi-chevron-right</v-icon>
        </div>
      </div> -->
      <!-- <div class="d-flex my-5">
        <PerformanceCard
          title="Performance"
          text="Wie wähle ich mein erstes Investment aus?"
          @next="bestPerformance"
        />
        <PerformanceCard
          title="Performance"
          text="5 Tipps zum Investieren"
          @next="bestPerformance"
          class="ml-5"
        />
        <PerformanceCard
          title="Performance"
          text="Was ist Private Equity?"
          @next="bestPerformance"
          class="ml-5"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'NewDeals',
  components: {
    ProductCard: lazyLoad('components/Marketplace/NewDeals/ProductCard'),
    CategoryCard: lazyLoad('components/Marketplace/NewDeals/CategoryCard'),
    // PerformanceCard: lazyLoad('components/Marketplace/NewDeals/PerformanceCard'),
  },
  async created() {
    if (this.allAssets.length > 0) {
      this.loading = false;
    }
    try {
      await this.$store.dispatch('assets/fetch');
    } catch (error) {
      this.$notify.error(error.message);
    }
    this.loading = false;
  },
  data() {
    return {
      slideIndices: {
        Art: 0,
        Vehicle: 0,
        RealEstate: 0,
      },
      loading: true,
      assetStatusList: [['Offered'], ['Published', 'Teased'], ['Financed']],
    };
  },
  computed: {
    ...mapGetters('assets', {
      allAssets: 'all',
    }),
  },
  methods: {
    assetsByStatus(statusList) {
      return this.allAssets.filter((asset) => statusList.includes(asset.assetStatus));
    },
    routeToProductView(productId) {
      this.$router.push({
        name: 'NewDealsProduct',
        params: { product: productId },
      });
    },
    bestPerformance() {
      // TODO: go to the best performance
    },
  },
};
</script>

<style lang="scss">
.marketplace {
  overflow-x: hidden;
  .product-list {
    overflow-x: scroll;
  }
  .v-skeleton-loader__image.v-skeleton-loader__bone {
    height: 250px;
  }
  .v-skeleton-loader__card-heading.v-skeleton-loader__bone {
    height: 155px;
  }
}
</style>
